<template>
  <div class="area">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      </ul>
    </div>
</template>
<script>
export default {

}
</script>

<style>
/* @import '../../assets/css/_squares.scss' */
</style>
